import './Header.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import ArrowDropDownIcon from '../../../general/assets/svg/ArrowDropDownIcon';
import BurgerMenuIcon from '../../../general/assets/svg/BurgerMenuIcon';
import CartIcon from '../../../general/assets/svg/CartIcon';
import CompareIcon from '../../../general/assets/svg/CompareIcon';
import LogoIcon from '../../../general/assets/svg/LogoIcon';
import ProfileIcon from '../../../general/assets/svg/ProfileIcon';
import WishListIcon from '../../../general/assets/svg/WishListIcon';
import { Theme } from '../../../general/contexts/ThemeContext';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useKey, { keys } from '../../../general/hooks/useKey';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import { RootState } from '../../../general/redux/store';
import { routesClient } from '../../../general/routes';
import createHelpCenterServiceService from '../../../general/services/helpCenter';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { ShopLink } from '../../../general/types/types';
import { QuestionGroup } from '../../pages/help-center/faq-and-qa/FAQ';
import ModalWindow from '../authorization/modal-window/ModalWindow';
import BreadCrumbs from '../bread-crumbs/BreadCrumbs';

interface HeaderProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const Header: React.FC<HeaderProps> = ({ theme, setTheme }) => {
  const location = useLocation();

  const { width } = useResize();

  const [isAuthorization, setIsAuthorization] = useState<boolean>(
    localStorage.getItem('referralCode') !== null,
  );

  const { data: retailCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_RETAIL,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue?.retailProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const { data: wholesaleCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_WHOLESALE,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.wholesaleProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const isUser = useSelector((state: RootState) => state.clps.cp2);
  const wislistCount = useSelector((state: RootState) => state.clps.wlc);
  const compareCount = useSelector((state: RootState) => state.compare.c);
  const cartCount = useSelector((state: RootState) => state.pc.count);

  useKey((event) => {
    if (event.key === keys.ESCAPE) {
      isAuthorization && setIsAuthorization(false);
    }
  });

  const toggleNavMenu = () => {
    const navMenu = document.querySelector('.header-list-links');
    const body = document.querySelector('body');
    if (navMenu && body) {
      const isOpen = navMenu.classList.contains('open-nav');
      body.classList.toggle('overflow-hidden', !isOpen);
      navMenu.classList.toggle('open-nav', !isOpen);
    }
  };

  const { data: faqs } = useCustomQuery<QuestionGroup[]>(queryKeys.FAQS, () =>
    createHelpCenterServiceService(clientInstance).getAllFAQ(),
  );

  const { data: qaes } = useCustomQuery<QuestionGroup[]>(queryKeys.QAES, () =>
    createHelpCenterServiceService(clientInstance).getAllQA(),
  );

  useEffect(() => {
    const navMenu = document.querySelector('.header-list-links');
    if (navMenu && navMenu.classList.contains('open-nav')) {
      document.querySelector('body')?.classList.remove('overflow-hidden');
      navMenu.classList.remove('open-nav');
    }
  }, [location.key, retailCount, wholesaleCount]);

  const formatCrumbName = (crumbName: string) => {
    return crumbName
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const makeCrumbs = () => {
    const crumbsNames = location.pathname.split('/');
    return crumbsNames
      .filter((crumbName) => !crumbName.includes('profile')) // Убираем все пути, содержащие 'profile'
      .map((crumbName, index) => ({
        title: crumbName === '' ? 'Home' : formatCrumbName(crumbName),
        path: crumbName === '' ? '/' : crumbsNames.slice(0, index + 1).join('/'),
      }));
  };

  const redirectToProducts = (): string => {
    if (
      retailCount &&
      wholesaleCount &&
      retailCount.priceCategoryCount > 0 &&
      wholesaleCount.priceCategoryCount <= 0
    ) {
      if (retailCount.machineCategoryCount) {
        return routesClient.productsRetail.path + `/${retailCount.machineCategoryCount}`;
      }

      return routesClient.productsRetail.path;
    } else if (
      retailCount &&
      wholesaleCount &&
      wholesaleCount.priceCategoryCount > 0 &&
      retailCount.priceCategoryCount <= 0
    ) {
      if (wholesaleCount.machineCategoryCount) {
        return routesClient.productsRetail.path + `/${wholesaleCount.machineCategoryCount}`;
      }

      return routesClient.productsWholesale.path;
    } else {
      return routesClient.productsRetail.path;
    }
  };

  const redirectToHelpCenter = (): string => {
    if (qaes && faqs) {
      if (qaes.length > 0 && faqs.length === 0) {
        return `/${routesClient.helpCenterQA.path}`;
      } else if (faqs.length > 0 && qaes.length === 0) {
        return `/${routesClient.helpCenterFAQ.path}`;
      }
    }
    return `/${routesClient.helpCenterQA.path}`; // Значение по умолчанию
  };

  return (
    <>
      <header className="header page-container">
        <nav className="nav-links">
          <div className="logo">
            <Link to={routesClient.home.path}>
              <LogoIcon />
              AsicXchange
            </Link>
          </div>
          <div className="default-links">
            <ul className="header-list-links">
              {width <= 768 && (
                <li className="nav-menu-mobile__actions">
                  <div
                    className="icons-action__element"
                    onClick={() => setIsAuthorization(isUser !== '' ? false : true)}
                  >
                    <Link to={isUser !== '' ? '/profile/' : `${routesClient.stub.path}`}>
                      <ProfileIcon isUser={isUser} theme={theme} />
                    </Link>
                  </div>
                  <div
                    className="icons-action__element"
                    onClick={() => setIsAuthorization(isUser !== '' ? false : true)}
                  >
                    {wislistCount !== 0 && (
                      <div className="cart-count-element">
                        <span>{wislistCount > 99 ? '99+' : wislistCount}</span>
                      </div>
                    )}
                    <Link to={isUser !== '' ? '/profile/wishlist' : `${routesClient.stub.path}`}>
                      <WishListIcon isUser={isUser} theme={theme} />
                    </Link>
                  </div>
                  <div className="icons-action__element">
                    {compareCount !== 0 && (
                      <div className="cart-count-element">
                        <span>{compareCount > 99 ? '99+' : compareCount}</span>
                      </div>
                    )}
                    <Link to={routesClient.compare.path}>
                      <CompareIcon isUser={isUser} theme={theme} />
                    </Link>
                  </div>
                  {/* <div className="icons-action__element">
                    <LightDarkButton
                      onChange={() => {
                        if (theme === themes.light) setTheme(themes.dark);
                        if (theme === themes.dark) setTheme(themes.light);
                      }}
                      value={theme === themes.dark}
                    />
                  </div> */}
                </li>
              )}
              <li className="list-link">
                <NavLink
                  to={routesClient.home.path}
                  className={({ isActive }) => (isActive ? ' active' : '')}
                >
                  Home
                </NavLink>
              </li>
              <li className="list-link">
                <NavLink to={routesClient.aboutUs.path}>About Us</NavLink>
              </li>
              <li className="list-link">
                <NavLink
                  to={redirectToProducts()}
                  className={({ isActive }) => (isActive ? ' active' : '')}
                >
                  Products
                  <ArrowDropDownIcon />
                </NavLink>
                {retailCount !== undefined &&
                  wholesaleCount !== undefined &&
                  retailCount.priceCategoryCount >= 1 &&
                  wholesaleCount.priceCategoryCount >= 1 && (
                    <ul>
                      <li>
                        <NavLink
                          to={routesClient.productsRetail.path}
                          className={({ isActive }) => (isActive ? ' activeLi' : '')}
                        >
                          Retail
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={routesClient.productsWholesale.path}
                          className={({ isActive }) => (isActive ? ' activeLi' : '')}
                        >
                          Wholesale
                        </NavLink>
                      </li>
                    </ul>
                  )}
              </li>
              <li className="list-link">
                <NavLink to={routesClient.pool.path}>
                  Services
                  <ArrowDropDownIcon />
                </NavLink>
                <ul>
                  <li>
                    <NavLink
                      to={routesClient.pool.path}
                      className={({ isActive }) => (isActive ? ' activeLi' : '')}
                    >
                      Pool
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={routesClient.hosting.path}
                      className={({ isActive }) => (isActive ? ' activeLi' : '')}
                    >
                      Hosting
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="list-link">
                <NavLink to={routesClient.blog.path}>Blog</NavLink>
              </li>
              <li className="list-link">
                <NavLink
                  to={redirectToHelpCenter()}
                  className={({ isActive }) => (isActive ? ' active' : '')}
                >
                  Help Center
                  <ArrowDropDownIcon />
                </NavLink>
                {qaes && faqs && (
                  <ul>
                    <li>
                      <NavLink
                        to={routesClient.helpCenterFAQ.path}
                        className={({ isActive }) => (isActive ? 'activeLi' : '')}
                      >
                        FAQ
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={routesClient.helpCenterQA.path}
                        className={({ isActive }) => (isActive ? 'activeLi' : '')}
                      >
                        Q&A
                      </NavLink>
                    </li>
                    {/* <li><NavLink to={`/help-center/guides`} className={({ isActive }) => (isActive ? "activeLi" : "")}>Guid lines</NavLink></li> */}
                  </ul>
                )}
              </li>
              <li className="list-link">
                <NavLink to={routesClient.contacts.path}>Contacts</NavLink>
              </li>
            </ul>
            <div className="header-nav-button">
              <button onClick={toggleNavMenu}>
                <BurgerMenuIcon />
              </button>
            </div>
          </div>
          {/* <div className="header__search">
            <button
              className="header-bottom__search-btn"
              onClick={() => isActiveSearch(false)}
              style={{ opacity: 1 }}
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.45605 1.4964L15.4561 15.4964M1.45605 15.4964L15.4561 1.4964"
                  stroke="#6858F4"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div> */}
          <div className="icons-action unselectable">
            {/* <div className="icons-action__element">
              <LightDarkButton
                onChange={() => {
                  if (theme === themes.light) setTheme(themes.dark);
                  if (theme === themes.dark) setTheme(themes.light);
                }}
                value={theme === themes.dark}
              />
            </div> */}
            <div
              className="icons-action__element"
              onClick={() => setIsAuthorization(isUser !== '' ? false : true)}
            >
              <Link
                to={isUser !== '' ? `${routesClient.profile.path}` : `${routesClient.stub.path}`}
              >
                <ProfileIcon isUser={isUser} theme={theme} />
              </Link>
            </div>
            <div
              className="icons-action__element"
              onClick={() => setIsAuthorization(isUser !== '' ? false : true)}
            >
              {wislistCount !== 0 && (
                <div className="cart-count-element">
                  <span>{wislistCount > 99 ? '99+' : wislistCount}</span>
                </div>
              )}
              <Link
                to={
                  isUser !== ''
                    ? `${routesClient.profileWishlist.path}`
                    : `${routesClient.stub.path}`
                }
              >
                <WishListIcon isUser={isUser} theme={theme} />
              </Link>
            </div>
            {/* <div className="icons-action__element">
              {compareCount !== 0 && (
                <div className="cart-count-element">
                  <span>{compareCount > 99 ? '99+' : compareCount}</span>
                </div>
              )}
              <Link to={routesClient.compare.path}>
                <CompareIcon isUser={isUser} theme={theme} />
              </Link>
            </div> */}
            <div className="icons-action__element">
              <Link to={routesClient.cart.path}>
                {cartCount !== 0 && (
                  <div className="cart-count-element">
                    <span
                      style={
                        cartCount > 9
                          ? { letterSpacing: '-1px', fontSize: '12px' }
                          : { letterSpacing: '0', fontSize: '14px' }
                      }
                    >
                      {cartCount > 99 ? '99+' : cartCount}
                    </span>
                  </div>
                )}
                <CartIcon isUser={isUser} theme={theme} />
              </Link>
            </div>
          </div>
        </nav>
        {location.pathname !== `${routesClient.home.path}` && <BreadCrumbs crumbs={makeCrumbs()} />}
      </header>
      {isAuthorization && <ModalWindow isActive={setIsAuthorization} />}
    </>
  );
};

export default Header;
