import { AxiosInstance } from 'axios';

interface CloudFlareService {
  clearClientCache: () => Promise<any>;
}

export const createCloudFlareService = (instance: AxiosInstance): CloudFlareService => ({
  async clearClientCache() {
    try {
      const response = await instance.post(`/CacheControll`);
      return response;
    } catch (error) {
      throw error;
    }
  },
});
