import './admin/assets/styles/AdminGlobal.scss';
import './client/assets/styles/global.scss';

import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import { googleFeatures } from './general/StaticData';
import InjectGtag from './injectGtag';
import { cleanCacheForage } from './localforage';

export const queryClient = new QueryClient();

const initializeApp = async () => {
  await cleanCacheForage();
  const rootElement = document.getElementById('root');

  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={googleFeatures.authClientId}>
          <InjectGtag />
          <App />
        </GoogleOAuthProvider>
      </QueryClientProvider>,
    );
  }
};

initializeApp();
