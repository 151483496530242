import './AdminOrder.scss';

import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import EditIconAction from '../../../../general/assets/svg/EditIconAction';
import LocationProdStoresIcon from '../../../../general/assets/svg/LocationProdStoresIcon';
import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createOrdersService from '../../../../general/services/orders';
import createShippingService from '../../../../general/services/shipping';
import { images } from '../../../../general/StaticData';
import { Address, Order } from '../../../../general/types/types';
import { formatToTwoPlaces } from '../../../../general/utils/Validations';
import ClientEditModal from './ClientEditModal';
import OrderPaymentStatus from './OrderPaymentStatus';
import OrderStatus from './OrderStatus';

const AdminOrder: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [addresses, setAddresses] = useState<Address[] | null>(null);
  const [orderInfo, setOrdersInfo] = useState<any[] | null>(null);
  const [isEditClient, setIsEditClient] = useState(false);

  const [orderReceipt, setOrderReceipt] = useState<any | null>(null);
  //const [validatingAddress, setValidatingAddress] = useState(false);

  const [currentFrame, setCurrentFrame] = useState('');

  const { data: order } = useCustomQuery<Order | null>(queryKeys.ORDER_ADMIN, () =>
    createOrdersService(adminInstance).getOrder(id as string),
  );

  const { data: transactionStatus } = useCustomQuery<any | null>(
    queryKeys.ORDER_TRANSACTION_STATUS,
    () => createOrdersService(adminInstance).getTransactionStatus(order as Order),
    undefined,
    undefined,
    {
      enabled: order != null,
    },
  );

  const { mutate: validateAddress, isLoading: validating } = useCustomMutation(
    createShippingService(adminInstance).ValidateAddress,
    undefined,
    {
      onSuccess: (response: any) => {
        const getValidationErrors = () => {
          const messages = response.messages;
          return messages
            .map((message: any, index: number) => `${index + 1}. ${message.text}`)
            .join('\n');
        };

        if (response.isValid) {
          dispatch(response.messages.length > 0 ? setType(types.WARNING) : setType(types.SUCCESS));
          dispatch(
            setInfo({
              n: response.messages.length > 0 ? types.WARNING : types.SUCCESS,
              r: {
                s: response.messages.length > 0 ? getValidationErrors() : 'Address is valid',
              },
            }),
          );
        } else {
          dispatch(setType(types.WARNING));
          dispatch(
            setInfo({
              n: types.WARNING,
              r: { s: getValidationErrors() },
            }),
          );
        }
      },
    },
  );

  const { mutate: putOrderStatus } = useCustomMutation(
    createOrdersService(adminInstance).putOrderStatus,
  );

  const { mutate: putOrderPaymeentStatus } = useCustomMutation(
    createOrdersService(adminInstance).putOrderPaymeentStatus,
  );

  const getStaticShippingCost = useCallback(() => {
    const priceWithoutShipping = order?.orderedProducts.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0,
    );

    return (
      (order?.totalPrice as number) - (priceWithoutShipping as number) - (order?.tax as number)
    );
  }, [order?.orderedProducts, order?.totalPrice, order?.tax]);

  const createOrdersInfo = useCallback(
    async (transactionAddress: Address) => {
      if (!order) return [];

      const baseInfo = [
        {
          field: 'Full name',
          value: order?.isQuickOrder ? (
            <>
              {order.transaction !== null || order?.productsStoresTransaction !== null ? (
                <>
                  {transactionAddress.name} {transactionAddress.lastName}
                </>
              ) : (
                <>{order?.firstName}</>
              )}
            </>
          ) : (
            <Link to={`/admin/clients/${order?.userId}`}>
              {order.transaction !== null || order?.productsStoresTransaction !== null ? (
                <>
                  {transactionAddress.name} {transactionAddress.lastName}
                </>
              ) : (
                <>{order?.lastName}</>
              )}
            </Link>
          ),
        },
        {
          field: 'Phone',
          value:
            order.transaction !== null || order?.productsStoresTransaction !== null
              ? transactionAddress.phone === '' || transactionAddress.phone === null
                ? '-'
                : transactionAddress.phone
              : order?.phoneNumber,
        },
        {
          field: 'Email',
          value:
            order.transaction !== null || order?.productsStoresTransaction !== null
              ? transactionAddress.email === '' || transactionAddress.email === null
                ? '-'
                : transactionAddress.email
              : order?.email,
        },
        {
          field: 'Contact method',
          value:
            order.transaction !== null || order?.productsStoresTransaction !== null
              ? order?.contacts === '' || order?.contacts === null
                ? '-'
                : order?.contacts
              : '-',
        },
      ];

      return [...baseInfo];
    },
    [order],
  );

  const removeNullFields = (obj: any) => {
    const cleanObject: any = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null) {
        cleanObject[key] = obj[key];
      }
    });
    return cleanObject;
  };

  useEffect(() => {
    if (order?.transaction != null) {
      createOrdersInfo(order?.transaction.addressTo).then(async (info) => {
        setOrdersInfo(info);

        setOrderReceipt(order?.receipt);

        if (order?.transaction != null) {
          const addressTo = order?.transaction?.addressTo;
          const billingAddress = order?.transaction?.billingAddress;
          setAddresses([addressTo, billingAddress]);
        }
      });
    } else if (order?.productsStoresTransaction != null) {
      createOrdersInfo(order.productsStoresTransaction.billingAddress).then(async (info) => {
        setOrdersInfo(info);

        setOrderReceipt(order?.receipt);

        const { billingAddress } = order.productsStoresTransaction;
        setAddresses([billingAddress]);
      });
    }
  }, [createOrdersInfo, order]);

  const setOrderStatusPayment = async () => {
    const newPaymentStatus =
      document.querySelector('.order-payment-status__current-options__name')?.innerHTML === 'Paid'
        ? true
        : false;
    if (newPaymentStatus !== order?.isPaid) {
      putOrderPaymeentStatus({
        orderId: id,
        isPaid: newPaymentStatus,
      });
    }
  };

  const setOrderStatus = async () => {
    const newStatus = document.querySelector('.order-status__current-options__name')?.innerHTML;
    if (newStatus !== order?.orderStatuses[0].name) {
      putOrderStatus({
        orderId: id,
        newStatus: newStatus,
      });
    }
  };

  const saveOrderChange = async () => {
    await setOrderStatus();
    await setOrderStatusPayment();

    dispatch(setType(types.SUCCESS));
    dispatch(
      setInfo({
        n: types.SUCCESS,
        r: { s: 'Status sucessfully updated' },
      }),
    );
  };

  const validateAddresses = async (address: Address) => {
    const removeIdField = (obj: any) => {
      const { ...rest } = obj;
      return rest;
    };

    const addressWithoutNullFields = removeNullFields(address);
    const addressWithoutId = removeIdField(addressWithoutNullFields);

    const addressToValidate = {
      address: { metadata: '', ...addressWithoutId },
    };
    validateAddress(addressToValidate);
  };

  const renderTextBlock = (title: string, value: string | undefined | null) => {
    if (!value) return null;
    return (
      <div className="client__text-block">
        <span className="client__text-block__title">{title}</span>
        <span className="client__text-block__text">{value}</span>
      </div>
    );
  };

  return (
    <>
      {order && (
        <div className="page-section">
          {isEditClient && <ClientEditModal isOpen={setIsEditClient} Order={order as Order} />}
          <div className="page-section__top">
            <div className="page-section__top__title">
              {order !== null && (
                <>
                  {order?.isQuickOrder && 'QUICK '}
                  ORDER <span style={{ color: '#25A8CF' }}>#{order?.idFormatted}</span>
                  <span>
                    {' '}
                    FROM {new Date(order?.createdAt).toLocaleString().split('/').join('.')}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="page-section__content">
            <div className="order-page-section">
              {order === null ? (
                <div className="order-page-section__preloader">
                  <Preloader />
                </div>
              ) : (
                <>
                  <div className="order-page-section__info-status">
                    <div className="order-page-section-info">
                      <div className="order-page-section-info__products-block">
                        <div className="order-page-section-info__products-block__top">
                          <span>Products</span>
                          <span>
                            <OrderPaymentStatus currentStatus={order?.isPaid} />
                          </span>
                        </div>
                        <div className="order-page-section-info__products-block__content">
                          <ul>
                            {order.orderedProducts.map((product, index) => (
                              <li key={index}>
                                <div className="order-product__info">
                                  <Link to={`/admin/products/product/${product.id}`}>
                                    <div className="order-product__info__img">
                                      <div
                                        className="selected-img"
                                        style={{
                                          backgroundImage: `url(${
                                            product?.image?.url || images.defaultImg
                                          })`,
                                          backgroundSize: 'contain',
                                          backgroundPosition: 'center',
                                          backgroundRepeat: 'no-repeat',
                                          height: '100%',
                                          width: '100%',
                                        }}
                                      />
                                    </div>
                                    <span className="order-product__info__name">
                                      {product.name}
                                    </span>
                                  </Link>
                                </div>
                                <div className="order-product__price-count">
                                  <span className="order-product__count">{product.quantity}</span> x{' '}
                                  <span className="order-product__price">
                                    {product.price -
                                      product.price * (product.percentageDiscount / 100)}{' '}
                                    $
                                  </span>
                                </div>
                              </li>
                            ))}
                            <li className="products-total___is-paid">
                              <span style={{ textTransform: 'uppercase' }}>
                                Shipping price:{' '}
                                <span className="products-total__price">
                                  {formatToTwoPlaces(getStaticShippingCost())} $
                                </span>
                              </span>
                              <span style={{ textTransform: 'uppercase' }}>
                                Subtotal price:{' '}
                                <span className="products-total__price">
                                  {formatToTwoPlaces(
                                    order.totalPrice - getStaticShippingCost() - order.tax,
                                  )}{' '}
                                  $
                                </span>
                              </span>
                              <span style={{ textTransform: 'uppercase' }}>
                                Tax:{' '}
                                <span className="products-total__price">
                                  {formatToTwoPlaces(order.tax)} $
                                </span>
                              </span>
                              <hr />
                              <span className="products-total">
                                Total price:{' '}
                                <span className="products-total__price">
                                  {formatToTwoPlaces(order.totalPrice)} $
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="order-page-section-info__client-block">
                        <div className="order-page-section-info__client-block__top">
                          <span>Client</span>
                          <button
                            onClick={() => {
                              setIsEditClient(true);
                            }}
                          >
                            <EditIconAction />
                          </button>
                        </div>
                        <div className="order-page-section-info__client-block__content">
                          {orderInfo?.map(({ field, value }, index) => (
                            <div
                              style={{
                                display: `${
                                  index === orderInfo.length - 1 || index === orderInfo.length - 2
                                    ? 'flex'
                                    : ''
                                }`,
                              }}
                              key={index}
                              className="client__text-block"
                            >
                              <span className="client__text-block__title">{field}</span>
                              {index === 0 ? (
                                <span className="client__text-block__text large-text">{value}</span>
                              ) : (
                                <span className="client__text-block__text">{value}</span>
                              )}
                            </div>
                          ))}

                          {order?.transaction !== null && (
                            <div
                              className="buttons-validators"
                              style={{ display: 'flex', gap: '20px' }}
                            >
                              <button
                                className="section-btn-gradient"
                                onClick={() => validateAddresses(order?.transaction?.addressTo)}
                              >
                                {!validating ? (
                                  'Validate shipping address'
                                ) : (
                                  <Preloader style={preloaderStyles.DARK} />
                                )}
                              </button>
                            </div>
                          )}
                          {order?.productsStoresTransaction && (
                            <div className="shipping-stores_locations">
                              <div className="available_stores">
                                {order?.productsStoresTransaction?.productsStores.map(
                                  (store, storeIndex) => (
                                    <div
                                      key={storeIndex}
                                      className="store-item"
                                      style={{ position: 'relative' }}
                                      onClick={() => {
                                        setCurrentFrame(store.mapFrame);
                                      }}
                                    >
                                      <LocationProdStoresIcon />
                                      <div className="info-block">
                                        <span className="country">{store.country}</span>
                                        <span className="address">{store.address}</span>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                              <div
                                className="map"
                                dangerouslySetInnerHTML={{ __html: currentFrame }}
                              ></div>
                            </div>
                          )}
                          <div className="client__text-block">
                            <div className="addresses">
                              {addresses?.map((address, index) => (
                                <div key={index}>
                                  {order?.productsStoresTransaction ? (
                                    <span className="header">Billing address</span>
                                  ) : (
                                    <span className="header">
                                      {index === 0 && 'Shipping address'}
                                      {index === 1 && 'Billing address'}
                                    </span>
                                  )}
                                  {renderTextBlock(
                                    'Full name:',
                                    `${address?.name} ${address?.lastName}`,
                                  )}
                                  {renderTextBlock('Country:', address?.country)}
                                  {renderTextBlock('City:', address?.city)}
                                  {renderTextBlock('State:', address?.state)}
                                  {renderTextBlock('Street 1:', address?.street1)}
                                  {renderTextBlock('Street 2:', address?.street2)}
                                  {renderTextBlock('Street 3:', address?.street3)}
                                  {renderTextBlock('Street number:', address?.streetNumber)}
                                  {renderTextBlock('Zip:', address?.zip)}
                                  {renderTextBlock('Email:', address?.email)}
                                  {renderTextBlock('Phone:', address?.phone)}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="order-page-section-status">
                      <div className="order-page-section-status__status-block">
                        <div className="order-page-section-status__status-block__top">
                          <span>Status</span>
                        </div>
                        <div className="order-page-section-status__status-block__content">
                          <OrderStatus
                            currentStatus={
                              order?.orderStatuses[order?.orderStatuses?.length - 1]?.name
                            }
                          />
                        </div>
                      </div>
                      {transactionStatus && transactionStatus.respCode === '200' && (
                        <div className="order-page-section-status__status-block">
                          <div className="order-page-section-status__status-block__top">
                            <span>Payment status</span>
                          </div>
                          <div className="order-page-section-status__status-block__content">
                            <div className="payment-status_field">
                              {Object.entries(transactionStatus).map(
                                ([key, value], index) =>
                                  value !== null && (
                                    <div key={index}>
                                      <span>{key}:</span>
                                      <span className="dots"></span>
                                      <span>{value as ReactNode}</span>
                                    </div>
                                  ),
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {order?.stabelCoinReciept && (
                        <div className="order-page-section-status__status-block">
                          <div className="order-page-section-status__status-block__top">
                            <span>Reciept</span>
                          </div>
                          <div className="order-page-section-status__status-block__content">
                            <div className="payment-status_field">
                              <div>
                                <span>Transaction ID \ Link</span>
                                <span className="dots"></span>
                                <span>{order?.stabelCoinReciept}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="order-page-section-status__status-block">
                        <div className="order-page-section-status__status-block__top">
                          <span>Order receipt</span>
                        </div>
                        <div className="order-page-section-status__status-block__content">
                          {orderReceipt ? (
                            <>
                              {orderReceipt.isImg ? (
                                <a
                                  href={orderReceipt.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div
                                    className="reciept"
                                    style={{
                                      backgroundImage: `url(${orderReceipt.url})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                      backgroundRepeat: 'no-repeat',
                                      height: '200px',
                                      width: '300px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </a>
                              ) : (
                                <a
                                  href={orderReceipt.url}
                                  className="receip_no-img"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Open receipt
                                </a>
                              )}
                            </>
                          ) : (
                            !orderReceipt &&
                            order?.isUploadLaterBankTransactionReciept && (
                              <span className="waiting-for-receipt">Waiting for receipt...</span>
                            )
                          )}
                        </div>
                      </div>

                      <div className="order-page-section-status__status-history-block">
                        <div className="order-page-section-status__status-history-block">
                          <div className="order-page-section-status__status-history-block__top">
                            <span>Status history</span>
                          </div>
                          <div className="order-page-section-status__status-history-block__content">
                            <ul>
                              {order?.orderStatuses?.reverse().map((orderStatus, index) => (
                                <li key={index}>
                                  <span
                                    className={`status-history-block__content__status order-${
                                      orderStatus.name.split(' ')[0]
                                    }`}
                                  >
                                    {orderStatus.name}
                                  </span>
                                  <span>
                                    {new Date(orderStatus?.createdAt)
                                      .toLocaleString()
                                      .split('/')
                                      .join('.')}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="save-proudct-section">
                    <button className="section-btn-gradient" onClick={saveOrderChange}>
                      Save
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminOrder;
