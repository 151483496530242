import './AdminEmail.scss';

import React, { useEffect, useState } from 'react';

import AddIcon from '../../../../general/assets/svg/AddIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import createAdminsService, { default as createEmailService } from '../../../../general/services/admins';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { AdminEmail, PaggingObj } from '../../../../general/types/types';
import AdminSearch from '../../../components/search/Search';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';
import ActionButtons from './ActionButtons';
import ModalAdminEmail from './ModalAdminEmail';

const AdminEmails: React.FC = () => {
  const { page, pageSize, searchQuery, setPage, setPageSize, setSearchQuery } = usePagination();
  const [isModalMessage, setIsModalMessage] = useState(false);

  const openModal = () => {
    setIsModalMessage(true);
  };

  const {
    data: admins,
    isLoading: emailsLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<AdminEmail>>(queryKeys.ADMIN_EMAILS, () =>
    createEmailService(adminInstance).getAllEmails(searchQuery, pageSize, page),
  );

  useEffect(() => {
    getData();
  }, [searchQuery, page, pageSize, getData]);

  const { mutate: deleteAdminEmail } = useCustomMutation(
    createAdminsService(adminInstance).deleteAdminEmail,
    undefined,
    {
      onSuccess: () => {
        getData();
      },
    },
  );

  const deleteEmail = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const emailId = e.currentTarget.dataset.id;
    if (emailId) {
      deleteAdminEmail(emailId);
    }
  };

  const actionButtons = (item: any, deleteMethod: any, refreshMethod: any) => (
    <ActionButtons item={item} deleteMethod={deleteMethod} refreshMethod={refreshMethod} />
  );

  return (
    <>
      {isModalMessage && (
        <ModalAdminEmail isActive={setIsModalMessage} email={null} refreshMethod={getData} />
      )}
      <div className="page-section__content">
        <div className="page-section__buttons">
          <button onClick={openModal}>
            <AddIcon color="white" />
            <span>Add email</span>
          </button>
        </div>
        <div>
          <AdminSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={'Search by email'}
          />
        </div>
        <div className="page-section__content__list-section">
          <div className="list-section__content">
            {emailsLoading && (
              <div className="page-update-overlay">
                <Preloader />
              </div>
            )}
            <CustomTable
              data={admins ? admins?.items : []}
              headers={['email', 'order-notification', 'contact-message-notification']}
              deleteMethod={deleteEmail}
              isAction={true}
              actionComponent={actionButtons}
              refreshMethod={getData}
            />
          </div>
        </div>
        {admins && admins?.pageSize < admins?.totalCount && (
          <CustomPagination
            currentPage={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            isNextPage={admins.hasNextPage}
            isPrevPage={admins.hasPreviousPage}
            total={admins.totalCount}
          />
        )}
      </div>
    </>
  );
};

export default AdminEmails;
