import './ProfileData.scss';

import React, { FocusEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import useScrollTo from '../../../../general/hooks/useScrollTo';
import { queryKeys } from '../../../../general/queryKeys';
import { setFieldsErrors } from '../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../../general/redux/store';
import { routesClient } from '../../../../general/routes';
import createClientService from '../../../../general/services/client';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { errorMessages, regexp, validateFields } from '../../../../general/utils/Validations';

interface User {
  name: string;
  surname: string;
  email?: string;
  phoneNumber?: string;
  telegram?: string;
  whatsApp?: string;
}

const ProfileData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollTo = useScrollTo();
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const userRef = useSelector((state: RootState) => state.clps.userRefCode);

  const {
    data: user,
    isLoading: userLoading,
    refetch: refreshUserData,
  } = useCustomQuery<User>(
    queryKeys.USER_INFO,
    () => createClientService(clientInstance).getClientInfo(userId),
    undefined,
    undefined,
    {
      enabled: !!userId,
    },
  );

  useEffect(() => {
    if (!userId) {
      navigate(routesClient.home.path);
      return;
    }

    const isUserDetailsIncomplete = !user?.name || !user?.surname || !user?.phoneNumber;

    if (userRef && userId && isUserDetailsIncomplete) {
      const elements = [
        { element: document.querySelector<HTMLElement>('#name_input'), value: user?.name },
        { element: document.querySelector<HTMLElement>('#surname_input'), value: user?.surname },
        { element: document.querySelector<HTMLElement>('#phone_input'), value: user?.phoneNumber },
      ];

      elements.forEach(({ element, value }) => {
        if (element && !value) {
          element.style.animation = 'pulse 1.5s infinite';

          setTimeout(() => {
            element.style.animation = '';
          }, 4000);
        }
      });
    }
  }, [navigate, userId, userRef, user?.name, user?.surname, user?.phoneNumber]);

  const fieldsErrors = useSelector((state: RootState) => state.clientCartProcessing.fieldsErrors);

  const { mutate: updateUser } = useCustomMutation(
    createClientService(clientInstance).putClientInfo,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Contacts succesfully saved' },
          }),
        );

        refreshUserData();
      },
    },
  );

  const scrolToTop = () => {
    scrollTo('top');
  };

  const updateUseerInfo = async () => {
    const inputs = document.querySelectorAll<HTMLInputElement>('.profile-personal-input');

    const required: boolean[] = [];
    const fieldsConfig = [
      {
        input: inputs[0],
        regex: regexp.string,
        errorField: 'name',
        errorMessage: errorMessages[0],
      },
      {
        input: inputs[1],
        regex: regexp.string,
        errorField: 'surname',
        errorMessage: errorMessages[0],
      },
      {
        input: inputs[2],
        regex: regexp.email_regexp,
        errorField: 'email',
        errorMessage: errorMessages[0],
        optional: true,
      },
      {
        input: inputs[3],
        regex: regexp.string,
        errorField: 'phone',
        errorMessage: errorMessages[0],
        optional: true,
      },
      {
        input: inputs[4],
        regex: regexp.string,
        errorField: 'telegram',
        errorMessage: errorMessages[0],
        optional: true,
      },
      {
        input: inputs[5],
        regex: regexp.string,
        errorField: 'whatsapp',
        errorMessage: errorMessages[0],
        optional: true,
      },
    ];

    dispatch(setFieldsErrors(validateFields(fieldsConfig, required)));
    scrolToTop();

    if (required.includes(false)) return;

    const user = {
      id: userId,
      name: inputs[0].value,
      surname: inputs[1].value,
      email: inputs[2].value,
      phoneNumber: inputs[3].value,
      telegram: inputs[4]?.value,
      whatsApp: inputs[5]?.value,
    };

    updateUser(user);
  };

  const setDefaultBorder = (e: FocusEvent<HTMLInputElement>) => {
    e.target.style.border = '1px solid #25A8CF';
    dispatch(setFieldsErrors([]));
  };

  return (
    <>
      <div id="top" className="profile-data-page">
        {userLoading && (
          <div className="profile-data-page__preloader">
            <Preloader />
          </div>
        )}
        <div className="profile-page__section">
          <div className="profile-page__personal-data__title">
            <h2 className="profile-page__block-title">Personal data</h2>
          </div>
          <div className="profile-page__personal-data__content">
            <div className="profile-page__content__input ">
              <input
                onFocus={setDefaultBorder}
                className="profile-personal-input"
                id="name_input"
                type="text"
                defaultValue={user?.name || ''}
                required
              />
              <label className="profile-personal-label">Name</label>
            </div>
            {fieldsErrors.find((obj: any) => obj.field === 'name')?.message && (
              <div>
                <div className="arrow"></div>
                <span className="error-text" style={{ color: '#DB6484' }}>
                  {fieldsErrors.find((obj: any) => obj.field === 'name')?.message}
                </span>
              </div>
            )}
            <div className="profile-page__content__input ">
              <input
                onFocus={setDefaultBorder}
                type="text"
                id="surname_input"
                className="profile-personal-input"
                defaultValue={user?.surname || ''}
                required
              />
              <label className="profile-personal-label">Surname</label>
            </div>
            {fieldsErrors.find((obj: any) => obj.field === 'surname')?.message && (
              <div>
                <div className="arrow"></div>
                <span className="error-text" style={{ color: '#DB6484' }}>
                  {fieldsErrors.find((obj: any) => obj.field === 'surname')?.message}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="profile-page__section">
          <div className="profile-page__contact__title">
            <h2 className="profile-page__block-title">Contact details</h2>
          </div>
          <div className="profile-page__contact__content">
            <div className="profile-page__content__input ">
              <input
                onFocus={setDefaultBorder}
                type="text"
                className="profile-personal-input"
                defaultValue={user?.email !== undefined ? `${user?.email || ''}` : ''}
                required
              />
              <label className="profile-personal-label">Email</label>
            </div>
            {fieldsErrors.find((obj: any) => obj.field === 'email')?.message && (
              <div>
                <div className="arrow"></div>
                <span className="error-text" style={{ color: '#DB6484' }}>
                  {fieldsErrors.find((obj: any) => obj.field === 'email')?.message}
                </span>
              </div>
            )}
            <div className="profile-page__content__input ">
              <input
                onFocus={setDefaultBorder}
                type="number"
                id="phone_input"
                className="profile-personal-input"
                defaultValue={user?.phoneNumber !== undefined ? `${user?.phoneNumber || ''}` : ''}
                required
              />
              <label className="profile-personal-label">Phone number</label>
            </div>
            {fieldsErrors.find((obj: any) => obj.field === 'phone')?.message && (
              <div>
                <div className="arrow"></div>
                <span className="error-text" style={{ color: '#DB6484' }}>
                  {fieldsErrors.find((obj: any) => obj.field === 'phone')?.message}
                </span>
              </div>
            )}
            <div className="profile-page__content__input ">
              <input
                onFocus={setDefaultBorder}
                type="text"
                className="profile-personal-input"
                defaultValue={user?.telegram || ''}
                required
              />
              <label className="profile-personal-label">Telegram</label>
            </div>
            {fieldsErrors.find((obj: any) => obj.field === 'telegram')?.message && (
              <div>
                <div className="arrow"></div>
                <span className="error-text" style={{ color: '#DB6484' }}>
                  {fieldsErrors.find((obj: any) => obj.field === 'telegram')?.message}
                </span>
              </div>
            )}
            <div className="profile-page__content__input ">
              <input
                onFocus={setDefaultBorder}
                type="text"
                className="profile-personal-input"
                defaultValue={user?.whatsApp || ''}
                required
              />
              <label className="profile-personal-label">WhatsApp</label>
            </div>
            {fieldsErrors.find((obj: any) => obj.field === 'whatsapp')?.message && (
              <div>
                <div className="arrow"></div>
                <span className="error-text" style={{ color: '#DB6484' }}>
                  {fieldsErrors.find((obj: any) => obj.field === 'whatsapp')?.message}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="profile-page__section" onClick={updateUseerInfo}>
          <button className="profile-page__section__save-btn">Save changes</button>
        </div>
      </div>
    </>
  );
};

export default ProfileData;
