import { AxiosInstance } from 'axios';

interface AdminsService {
  postAdminEmail: (body: {
    email: string;
    orderNotification: boolean;
    contactMessageNotification: boolean;
  }) => Promise<any>;
  putAdminEmail: (body: {
    id: string;
    email: string;
    orderNotification: boolean;
    contactMessageNotification: boolean;
  }) => Promise<any>;
  deleteAdminEmail: (id: string) => Promise<any>;
  getAdmin: (id: string) => Promise<any>;
  getAllEmails: (query: string, pageSize: number, page: number) => Promise<any>;
  getAdminRoles: (id: string) => Promise<any>;
  getAllRoles: () => Promise<any>;
  postNewAdmin: (data: any) => Promise<any>;
  getAllAdmins: (query: string, pageSize: number, page: number) => Promise<any>;
  deleteAdmin: (data: { id: string; authorizedAdminId: string }) => Promise<any>;
  putAdmin: (data: any) => Promise<any>;
  resetPasswordByOwner: (id: string) => Promise<any>;
  putAdminInfo: (data: {
    authorizedAdminId: string;
    name: string;
    surname: string;
    username: string;
    email: string;
  }) => Promise<any>;
  putAdminPassword: (data: {
    authorizedAdminId: string;
    oldPassword: string;
    newPassword: string;
  }) => Promise<any>;
}

const createAdminsService = (instance: AxiosInstance): AdminsService => ({
  async postAdminEmail(body: {
    email: string;
    orderNotification: boolean;
    contactMessageNotification: boolean;
  }) {
    try {
      const response = await instance.post(`/AdminEmail`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putAdminEmail(body: {
    id: string;
    email: string;
    orderNotification: boolean;
    contactMessageNotification: boolean;
  }) {
    try {
      const response = await instance.put(`/AdminEmail`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteAdminEmail(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await instance.delete(`/AdminEmail`, { data });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putAdminPassword(data: {
    authorizedAdminId: string;
    oldPassword: string;
    newPassword: string;
  }) {
    try {
      const response = await instance.put(`/Admin/changeAdminPassword`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putAdminInfo(data: {
    authorizedAdminId: string;
    name: string;
    surname: string;
    username: string;
    email: string;
  }) {
    try {
      const response = await instance.put(`/Admin/adminInfo`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAdminRoles(id: string) {
    try {
      const response = await instance.get(`/Admin/adminRoles?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async resetPasswordByOwner(id: string) {
    try {
      const response = await instance.put(`/Admin/resetPasswordByOwner`, {
        adminId: id,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putAdmin(data: any) {
    try {
      const response = await instance.put(`/Admin/adminInfoByOwner`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteAdmin(data: { id: string; authorizedAdminId: string }) {
    try {
      const response = await instance.delete(`/Admin`, { data });
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllAdmins(query: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/Admin/all?SearchQuery=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async postNewAdmin(data: any) {
    try {
      const response = await instance.post(`/Admin/createAdmin`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAdmin(id: string) {
    try {
      const response = await instance.get(`/Admin/byId?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllEmails(query: string, pageSize: number, page: number) {
    try {
      const response = await instance.get(
        `/AdminEmail?Name=${encodeURIComponent(query)}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllRoles() {
    try {
      const response = await instance.get(`/Admin/roles`);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createAdminsService;
