import './AddRefferal.scss';

import React, { ChangeEvent, useReducer, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import useCustomMutation from '../../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import useRequired from '../../../../../general/hooks/useRequired';
import { queryKeys } from '../../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../../../general/redux/store';
import { adminInstance } from '../../../../../general/services/main/axiosInstances';
import createRefferalService from '../../../../../general/services/refferal';
import { Affiliate, PaggingObj } from '../../../../../general/types/types';
import AdminModalWindow from '../../../../components/modal-window/ModalWindow';
import AddAffiliate from '../../bloggersAndCompanies/addAffiliate/AddAffiliate';

interface AddReferralProps {
  close: () => void;
  modalAddAffiliates: boolean;
  setModalAddAffiliates: () => void;
}

const AddReferral: React.FC<AddReferralProps> = ({
  close,
  modalAddAffiliates,
  setModalAddAffiliates,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { referralCodes } = useSelector((state: RootState) => state.referralCode);

  const idsInRefs = referralCodes.map((code: any) => code.affiliate.id);
  const { errors, setError } = useRequired();
  const [isUmt, setIsUmt] = useState<boolean>(false);

  const [umtValues, setUmtValues] = useState<{
    umtSource: string;
    umtMedium: string;
    utmCampaign: string;
  }>({
    umtSource: '',
    umtMedium: '',
    utmCampaign: '',
  });

  const handleUmtChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setUmtValues((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [affiliateInfo, setAffiliateInfo] = useReducer(
    (_: string, info: string) => info,
    'Choose affiliate',
  );
  const [choosenAffiliateId, setChoosenAffiliateId] = useState<string | undefined>();

  const [affiliatesDropDown, setAffiliatesDropDown] = useReducer((temp: boolean) => !temp, false);

  //#region cors

  const { data: affiliatesForRefs } = useCustomQuery<PaggingObj<Affiliate>>(
    queryKeys.REFFERAL_AFFILIATES,
    () => createRefferalService(adminInstance).getAffiliates(1, 100, ''),
    undefined,
    undefined,
    {
      keepPreviousData: true,
    },
  );

  const { mutate: create } = useCustomMutation(
    createRefferalService(adminInstance).createRefferalProgram,
    undefined,
    {
      onSuccess: () => {
        queryClient.refetchQueries(queryKeys.REFFERAL_CODES);
      },
    },
  );
  //#endregion

  const handleDropdownItemClick = (info: string) => {
    setAffiliateInfo(info);
    setAffiliatesDropDown();
  };

  const handleSave = () => {
    const missingFields = [];

    if (!umtValues.umtSource) {
      missingFields.push('umt source');
    }
    if (!umtValues.umtMedium) {
      missingFields.push('umt medium');
    }
    if (!umtValues.utmCampaign) {
      missingFields.push('utm campaign');
    }

    if (missingFields.length > 0) {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: `Fields ${missingFields.join(', ')} are missing` },
        }),
      );

      return;
    }

    if (affiliateInfo === 'Choose affiliate') {
      setError('affiliate', 'Affiliate is required');
      return;
    }

    if (choosenAffiliateId) {
      create({
        affiliateId: choosenAffiliateId,
        umtSource: umtValues.umtSource,
        umtMedium: umtValues.umtMedium,
        utmCampaign: umtValues.utmCampaign,
      });
    }

    close();
  };

  return (
    <div className="add-refferal__content">
      <button
        className={`drop-down-btn ${errors.affiliate ? 'error_required' : ''}`}
        onClick={setAffiliatesDropDown}
      >
        {affiliateInfo}
        <svg
          style={{ transform: affiliatesDropDown ? 'rotateZ(180deg)' : '' }}
          width="17"
          height="11"
          viewBox="0 0 17 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.99999 9.62L8.61999 2L16.24 9.62"
            stroke="white"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          ></path>
        </svg>
      </button>
      {affiliatesDropDown && (
        <div className="drop-down__content">
          {affiliatesForRefs?.items.map((affiliate, index) => (
            <div
              key={index}
              className={`drop-down__ellement ${
                affiliateInfo === `${affiliate.firstName} / ${affiliate.nickName}` ? 'choosen' : ''
              } ${idsInRefs.includes(affiliate.id as string) ? 'disabled-div' : ''}`}
              onClick={() => {
                setChoosenAffiliateId(affiliate.id);
                handleDropdownItemClick(`${affiliate.firstName} / ${affiliate.nickName}`);
              }}
            >{`${index + 1}. ${affiliate.firstName} / ${affiliate.nickName}`}</div>
          ))}
        </div>
      )}
      <div className="umts">
        <div className="check-box__container">
          <input
            type="checkbox"
            id={`isUmt`}
            name="isActive"
            checked={isUmt}
            onChange={() => {
              setIsUmt(!isUmt);
            }}
          />
          <label htmlFor="isUmt"></label>
        </div>
        <div className="inputs">
          <input
            className={`input_symbols_panel ${!isUmt ? 'input-siabled' : ''}`}
            disabled={!isUmt}
            placeholder="umt source"
            type="text"
            name="umtSource"
            value={umtValues.umtSource}
            onChange={handleUmtChange}
          />
          <input
            className={`input_symbols_panel ${!isUmt ? 'input-siabled' : ''}`}
            disabled={!isUmt}
            placeholder="umt medium"
            type="text"
            name="umtMedium"
            value={umtValues.umtMedium}
            onChange={handleUmtChange}
          />
          <input
            className={`input_symbols_panel ${!isUmt ? 'input-siabled' : ''}`}
            disabled={!isUmt}
            placeholder="umt campaing"
            type="text"
            name="utmCampaign"
            value={umtValues.utmCampaign}
            onChange={handleUmtChange}
          />
        </div>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <button className="button_with_border" onClick={setModalAddAffiliates}>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 8.5H15.28" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
            <path
              d="M7.63989 0.859863V16.1399"
              stroke="#FFF9F9"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
          <span>Add new affiliate</span>
        </button>
        <button onClick={handleSave} className="section-btn-gradient">
          Save
        </button>
      </div>
      {modalAddAffiliates && (
        <AdminModalWindow title={'Add Affiliate'} isOpen={setModalAddAffiliates} mWidth={'50%'}>
          <AddAffiliate close={setModalAddAffiliates} />
        </AdminModalWindow>
      )}
    </div>
  );
};

export default AddReferral;
