import { AxiosInstance } from 'axios';

import { Affiliate, Link } from '../types/types';

interface RefferalService {
  getClientsTracking: (page: number, pageSize: number, searchQuery: string) => Promise<any>;
  getRefferalPrograms: (page: number, pageSize: number, searchQuery: string) => Promise<any>;
  getAffiliates: (page: number, pageSize: number, searchQuery: string) => Promise<any>;
  updateAffiliate: (body: Affiliate) => Promise<any>;
  createAffiliate: (body: Affiliate) => Promise<any>;
  addSocialNetowrkToAffiliate: (body: Link) => Promise<any>;
  deleteSocialNetowrkFromAffiliate: (id: string) => Promise<any>;
  getXslxUsersWithRefs: () => Promise<any>;
  getAffiliateInfo: (id: string) => Promise<any>;
  deleteAffiliate: (id: string) => Promise<any>;
  createRefferalProgram: (body: {
    affiliateId: string;
    umtSource: string;
    umtMedium: string;
    utmCampaign: string;
  }) => Promise<any>;
}

const createRefferalService = (instance: AxiosInstance): RefferalService => ({
  async createRefferalProgram(body: {
    affiliateId: string;
    umtSource: string;
    umtMedium: string;
    utmCampaign: string;
  }) {
    try {
      const response = await instance.post(`/ReferralCodes`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteAffiliate(id: string) {
    try {
      const response = await instance.delete(`/Affiliate`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id: id,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAffiliateInfo(id: string) {
    try {
      const response = await instance.get(`/Affiliate/byId?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getXslxUsersWithRefs() {
    try {
      const headers: any = {
        'Content-Type': 'blob',
        responseType: 'arraybuffer',
      };
      const response = await instance.get(`/User/xlsxUsersWithReferralCode`, headers);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteSocialNetowrkFromAffiliate(id: string) {
    try {
      const response = await instance.delete(`/Affiliate/socialNetwork`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id: id,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async addSocialNetowrkToAffiliate(body: Link) {
    try {
      const response = await instance.post(`/Affiliate/socialNetwork`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async createAffiliate(body: Affiliate) {
    try {
      const responce = await instance.post(`/Affiliate`, body);
      return responce;
    } catch (error) {
      return error;
    }
  },

  async updateAffiliate(body: Affiliate) {
    try {
      const response = await instance.put(`/Affiliate`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAffiliates(page: number, pageSize: number, searchQuery: string) {
    try {
      const response = await instance.get(
        `/Affiliate/all?SearchQuery=${searchQuery}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getRefferalPrograms(page: number, pageSize: number, searchQuery: string) {
    try {
      const response = await instance.get(
        `/ReferralCodes/all?SearchQuery=${searchQuery}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getClientsTracking(page: number, pageSize: number, searchQuery: string) {
    try {
      const response = await instance.get(
        `/User/allWithReferralCode?SearchQuery=${searchQuery}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createRefferalService;
