import './Home.scss';

import { useGoogleOneTapLogin } from '@react-oauth/google';
import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArrowRightIcon from '../../../general/assets/svg/ArrowRightIcon';
import ContactUsIcon from '../../../general/assets/svg/ContactUsIcon';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useGoogleCredentials from '../../../general/hooks/useGoogleCredentials';
import useScrollTo from '../../../general/hooks/useScrollTo';
import { queryKeys } from '../../../general/queryKeys';
import { setInfo, setType, types } from '../../../general/redux/reducers/Error-Reducer';
import { setIsOpened } from '../../../general/redux/reducers/GetInTouch-Reducer';
import { RootState } from '../../../general/redux/store';
import { routesClient } from '../../../general/routes';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { environment, environments } from '../../../general/StaticData';
import { ShopLink } from '../../../general/types/types';
import { createCredentials } from '../../components/authorization/google-authorization/GoogleAuth';
import ModalWindow from '../../components/authorization/modal-window/ModalWindow';
import ContactForm from '../../components/contact-form/ContactForm';
import NewMessage from '../../components/modals-window/NewMessage';
import CryptoStatistics from '../../components/running-line/CryptoStatistics';
import SponsorsLine from '../../components/running-line/sponsors/SponsorsLine';
import Showcase from '../../components/showcase/Showcase';
import ReviewsSlider from '../../components/sliders/reviews-slider/ReviewsSlider';
import SomeInfoSlider from './SomeInfoSlider';
import VideoBlock from './VideoBlock';

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollTo = useScrollTo();
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const setGoogleCredentials = useGoogleCredentials();

  const { data: retailCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_RETAIL,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue?.retailProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const { data: wholesaleCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_WHOLESALE,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.wholesaleProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      setGoogleCredentials(createCredentials(credentialResponse));
    },
    cancel_on_tap_outside: false,
    disabled: userId !== '',
    use_fedcm_for_prompt:
      environment === environments.DEV_SITE || environment === environments.PROD,
    onError: () => {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Ooops... Login Failed' },
        }),
      );
    },
  });

  const redirectProducts = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (
      retailCount &&
      wholesaleCount &&
      retailCount.priceCategoryCount > 0 &&
      wholesaleCount.priceCategoryCount <= 0
    ) {
      if (retailCount.machineCategoryCount) {
        navigate(routesClient.productsRetail.path + `/${retailCount.machineCategoryCount}`);
        return;
      }

      navigate(routesClient.productsRetail.path);
    } else if (
      retailCount &&
      wholesaleCount &&
      wholesaleCount.priceCategoryCount > 0 &&
      retailCount.priceCategoryCount <= 0
    ) {
      if (wholesaleCount.machineCategoryCount) {
        navigate(routesClient.productsRetail.path + `/${wholesaleCount.machineCategoryCount}`);
        return;
      }

      navigate(routesClient.productsWholesale.path);
    } else {
      navigate(routesClient.productsRetail.path);
    }
  };

  return (
    <>
      {isNewMessage && <NewMessage isOpen={setIsNewMessage} />}
      {isAuth && <ModalWindow isActive={setIsAuth} />}
      <section className="introduction-section section page-container">
        <div className="introduction-section__content">
          <h1 className="introduction-section__content-h1">
            YOUR BITCOIN
            <p>MINING STARTS</p>
            HERE!
            <article className="circleSVG" />
            <article className="circleSVG" />
            <article className="circleSVG" />
            <article className="circleSVG" />
            <article className="circleSVG" />
          </h1>
          <div className="introduction-section__content-btn ">
            <button
              className="section-btn-gradient"
              onClick={() => {
                //dispatch(setIsModal(true)); // For mobile
                dispatch(setIsOpened(true));
                scrollTo('get-in-touch');
              }}
            >
              <ContactUsIcon />
              Contact Us
            </button>
          </div>
        </div>
        <div className="introduction-section__video" data-nosnippet>
          <VideoBlock />
        </div>
        <div className="introduction-section__slider">
          <SomeInfoSlider />
        </div>
      </section>
      <section className="crypto-section">
        <CryptoStatistics marqueeClass="" />
      </section>
      <section id="showcase" className="banner-section page-container unselectable">
        <div className="banner-section-top">
          <div className="banner-section-top__text">
            <h1 className="banner-section-top__text-h1"> ASIC Miners</h1>
            <p className="banner-section-top__text-p">
              Profit
              <br />
              Growth
              <br />
              Performance
            </p>
          </div>
          <button onClick={redirectProducts} className="banner-section-top__link">
            <span>See All</span>
            <ArrowRightIcon fill="#424040" />
          </button>
        </div>
        <div className="banner-section-content__cs">
          <Showcase setIsAuth={setIsAuth} />
        </div>
      </section>
      <section className="sponsors-section">
        <SponsorsLine />
      </section>
      <section className="reviews-section  page-container">
        <div className="reviews-section__title">
          <h1 className="reviews-section__title-h1 section-h1">Our testimonials</h1>
          <p className="reviews-section__title-p section-p">
            Satisfied clients speak for us - we offer only the best in the mining world.
          </p>
        </div>
        <div className="reviews-section__reviews">
          <ReviewsSlider />
        </div>
      </section>
      <ContactForm />
    </>
  );
};

export default Home;
