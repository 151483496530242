import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { environments } from './general/StaticData';

const InjectGtag = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === environments.PROD) {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_TRACKING_ID}`;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GOOGLE_TRACKING_ID}');
      `;
      document.head.appendChild(script2);
    }
  }, []);

  return ReactDOM.createPortal(null, document.head);
};

export default InjectGtag;
