export const queryKeys = {
  NEW_ORDERS_COUNT: 'new_orders_count',
  NEW_MESSAGES_COUNT: 'new_messages_count',
  NEW_SIMPLE_MESSAGES_COUNT: 'simple_messages_count',
  HOSTING_LOCATION_STATES: 'hosting_locations_states',
  HOSTING_LOCATIONS: 'hosting_locations',
  GOOGLE_CREDENTIALS: 'google_credentials',
  INFO_BLOCK_TO_EDIT: 'info_block_to_edit',
  CLIENT_IN_ADMIN: 'client_in_admin',
  CLIENT_IN_ADMIN_ORDERS: 'client_in_admin_orders',
  AFFILIATE: 'affiliate',
  REFFERAL_XLSX: 'xlsx_users_with_refs',
  COMPANY_CONTACTS: 'company_contacts',
  FAQS: 'faqs',
  QAES: 'qaes',
  HOME_VIDEO: 'home_video',
  INFO_BLOCK: 'info_block',
  COMPANY_PARTNER_TO_EDIT: 'company_partner_to_edit',
  COMPANY_PARTNERS: 'company_partners',
  SHOW_CASE: 'show_case',
  TESTIMONIALS: 'testimonials',
  TESTIMONIAL_BY_ID: 'testimonial_by_id',
  TESTIMONIALS_FULL: 'testimonials_full',
  STAFF: 'staff',
  ABOUT_US_PICTURES: 'about_us_pictures',
  PROFILE_ORDERS: 'profile_orders',
  SHIPPING_COST: 'shipping_cost',
  BLOGS: 'blogs',
  CRYPTOS_LIST: 'cryptos_list',
  LINKS_SOME_INFO: 'links_some_info',
  BANK_TRANSFER_INFOS: 'bank_transfer_infos',
  STABLE_COIN_INFOS: 'stable_coin_infos',
  CURRENT_ARTICLE: 'current_article',
  ARTICLES_ADMIN: 'articles_admin',
  ARTICLES_CLIENT: 'articles_client',
  USER_INFO: 'user_info',
  ADMIN_INFO: 'admin_info',
  ADMIN_EMAILS: 'admin_emails',
  ADMIN_ROLES: 'admin_roles',
  ALL_ROLES: 'all_roles',
  CURRENT_ADMIN: 'current_admin',
  ADMINS: 'admins',
  ADMIN_LOGS: 'admin_logs',
  AVAILABLE_PROD_LOCATIONS: 'available_prod_locations',
  AVAILABLE_STORES_LOCATIONS: 'available_stores_locations',
  PRODUCTS_ADMIN: 'products_admin',
  ORDERS_ADMIN: 'orders_admin',
  ORDER_ADMIN: 'order_admin',
  ORDER_TRANSACTION_STATUS: 'order_transaction_status',
  MESSAGES_COUNT_ADMIN: 'messages_count_admin',
  MESSAGES_ADMIN: 'messages_admin',
  SIMPLE_MESSAGES_COUNT_ADMIN: 'simple_messages_count_admin',
  SIMPLE_MESSAGES_ADMIN: 'simple_messages_admin',
  ADMIN_CLIENTS: 'admin_clients',
  ADMIN_SUBSCRIBERS: 'admin_subscribers',
  REFFERAL_CLIENT: 'refferal_client',
  REFFERAL_CODES: 'refferal_codes',
  REFFERAL_AFFILIATES: 'refferal_affiliates',
  ADMIN_SHOW_CASE: 'admin_show_case',
  PRODUCT_CATEGORIES_RETAIL: 'product_categories_retail',
  PRODUCT_CATEGORIES_WHOLESALE: 'product_categories_wholesale',
  PRODUCT_CATEGORIES: 'product_categories',
  PRODUCT_CATEGORY: 'product_category',
  PRODUCT_CATEGORY_TABLE: 'product_category_table',
  PRODUCT_CATEGORY_CUERRENT: 'product_category_current',
  PRODUCT_FILTERS_MAIN_MINING: 'product_filters_main_mining',
  PRODUCT_FILTERS_MAIN_GENERAL_OF_CATEGORY: 'product_filters_main_general_of_category',
  PRODUCTS_CLIENTS: 'products_clients',
  PRODUCT_CLIENT: 'product_client',
  PRODUCT_CLIENT_MAIN_CHARACTERISTICS: 'product_client_main_characteristics',
  PRODUCTS_MAIN_CHARACTERISTICS: 'products_main_characteristics',
  PRODUCTS_MAIN_NAMES: 'products_main_names',
  PRODUCT_EDIT: 'product_edit',
};
