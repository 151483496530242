import './Styles/buttonStyles.scss';
import './Styles/editorStyles.scss';
import './Styles/toolbarStyles.scss';
import './Styles/urlToolbarStyles.scss';
import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/text-alignment/lib/plugin.css';

import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  BlockquoteButton,
  BoldButton,
  CodeButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createTextAlignmentPlugin from '@draft-js-plugins/text-alignment';
import createVideoPlugin from '@draft-js-plugins/video';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { ArticleServices } from '../../../../service/ArticleServices';
import { MailingServices } from '../../../../service/MailingServices';
import converterFromHTML from './converters/convertFromHtml';
import converterToHTML from './converters/convertToHtml.js';
import HeadlinesButton from './headlines/Headlines';
import mockUpload from './mockUpload';
import alignmentStyles from './Styles/alignmentStyles.module.scss';

export const sendImgSettingsConstatns = {
  article: 'article',
  mailing: 'mailing',
};

//#region plugin instance
const textAlignmentPlugin = createTextAlignmentPlugin({
  theme: { alignmentStyles },
});

const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: {
    toolbarStyles: {
      toolbar: 'inlineToolbar',
    },
    buttonStyles: {
      button: 'inlineButton',
      buttonWrapper: 'inlineButtonWrapper',
      active: 'inlineButtonActive',
    },
  },
});

const linkPlugin = createLinkPlugin();
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);
const imagePlugin = createImagePlugin({ decorator });
const videoPlugin = createVideoPlugin();

const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  handleUpload: mockUpload,
  addImage: imagePlugin.addImage,
});

const { InlineToolbar } = inlineToolbarPlugin;
const { AlignmentTool } = alignmentPlugin;

const plugins = [
  textAlignmentPlugin,
  dragNDropFileUploadPlugin,
  blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  imagePlugin,
  videoPlugin,
  inlineToolbarPlugin,
  linkPlugin,
];
//#endregion

const TextEditor = forwardRef(
  ({ setDescriptionPost, errorDescriptionRequired, sendImgSettings, isNeedVideo }, ref) => {
    const regexLink = /youtu\.be\/([^?]+)/;
    const regetLinkUsed = /https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)\?si=[a-zA-Z0-9_-]+/;

    const [isValudatingUrlError, setIsValudatingUrlError] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const editorRef = useRef();
    const editorField = useRef();

    const onChange = (newEditorState) => {
      setEditorState(newEditorState);
      setDescriptionPost(newEditorState.getCurrentContent());
    };

    const focusEditor = () => editorRef.current.focus();

    //#region video
    const urlError = () => {
      setTimeout(() => {
        setIsValudatingUrlError(false);
      }, 2000);
      return 'error_required';
    };

    const extractYouTubeVideoId = (url) => {
      const match = url.match(regexLink);
      if (match && match[1]) return match[1];
      else return null;
    };

    const isValidUrl = (url) => {
      const urlRegex = regexLink;
      return urlRegex.test(url);
    };

    const addVideo = (src) => {
      if (isValidUrl(src)) {
        const newEditorState = videoPlugin.addVideo(editorState, {
          src: src,
        });
        onChange(newEditorState);
      } else {
        setIsValudatingUrlError(true);
        setVideoUrl('');
      }
    };
    //#endregion

    useImperativeHandle(ref, () => {
      return {
        fromHTML(html) {
          const contentState = convertFromHTML(converterFromHTML())(html);
          setEditorState(EditorState.createWithContent(contentState));
        },

        toHTML() {
          return convertToHTML(converterToHTML(regetLinkUsed, extractYouTubeVideoId))(
            editorState.getCurrentContent(),
          );
        },
      };
    });

    const handleAddMedia = (event) => {
      const selectedMedia = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = (e) => {
        if (selectedMedia.type.includes('image')) {
          const img = new Image();
          const editor = document.getElementsByClassName('editor');

          img.onload = function () {
            const newWidth =
              this.width > editor[0].clientWidth ? editor[0].clientWidth : this.width;

            const addNewImg = (img) => {
              const newEditorState = imagePlugin.addImage(editorState, img, {
                width: newWidth * 0.05,
              });
              onChange(newEditorState);
            };

            switch (sendImgSettings) {
              case sendImgSettingsConstatns.article:
                new Promise(() => {
                  ArticleServices.postArticleContentImage(selectedMedia).then((responce) => {
                    addNewImg(responce);
                  });
                });
                break;
              case sendImgSettingsConstatns.mailing:
                new Promise(() => {
                  MailingServices.postImage(selectedMedia).then((responce) => addNewImg(responce));
                });
                break;
              default:
                break;
            }
          };

          img.src = e.target.result;
        }
      };
      reader.readAsDataURL(selectedMedia);
      event.target.value = '';
    };

    const addVideoUrl = {
      margin: '0px',
    };

    return (
      <div className="text-editor__container" style={{ marginTop: '20px', height: '430px' }}>
        <div className="label_with_options">
          <label className="input_header" htmlFor="postName">
            Description
          </label>
          <div>
            <button onClick={() => document.getElementById('fileInputMedia').click()}>
              Add media
            </button>
            <input
              id="fileInputMedia"
              type="file"
              accept="image/*"
              onChange={handleAddMedia}
              style={{ display: 'none' }}
            />
          </div>
        </div>
        <div style={{ display: isNeedVideo ? 'flex' : 'none' }} className="url">
          <button onClick={() => addVideo(videoUrl)}>Add</button>
          <input
            style={addVideoUrl}
            className={isValudatingUrlError ? urlError() : ''}
            type="text"
            placeholder="Incert video URL (Share -> Copy URL)"
            value={videoUrl}
            onChange={(event) => setVideoUrl(event.target.value)}
          />
        </div>
        <div
          ref={editorField}
          onClick={focusEditor}
          className={errorDescriptionRequired ? 'error_required' : 'editor'}
        >
          <Editor
            placeholder={errorDescriptionRequired ? errorDescriptionRequired : ''}
            editorKey="CustomInlineToolbarEditor"
            editorState={editorState}
            onChange={onChange}
            plugins={plugins}
            ref={editorRef}
          />
          <InlineToolbar>
            {(externalProps) => (
              <div>
                <div>
                  <HeadlinesButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <BoldButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                </div>
                <div>
                  <textAlignmentPlugin.TextAlignment {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                </div>
                <div>
                  <CodeButton {...externalProps} />
                  <BlockquoteButton {...externalProps} />
                  <linkPlugin.LinkButton {...externalProps} />
                </div>
              </div>
            )}
          </InlineToolbar>
          <AlignmentTool />
        </div>
      </div>
    );
  },
);

export default TextEditor;
